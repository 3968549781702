import { all } from "redux-saga/effects"
import questionnairesWatcher from "./questionnaires"
import reportsWatcher from "./reports"
import usersWatcher from "./users"
import currentQuestionnaireWatcher from "./currentQuestionnaire"
import currentReportWatcher from "./currentReport"
import vehicleWatcher from "./vehicles"
import operatorWatcher from "./operators"
import airlineWatcher from "./airlines"
import registrationWatcher from "./registrations"
import aircraftWatcher from "./aircraft"
import areaWatcher from "./areas"

const watchers = [
  questionnairesWatcher,
  reportsWatcher,
  usersWatcher,
  currentQuestionnaireWatcher,
  currentReportWatcher,
  vehicleWatcher,
  airlineWatcher,
  registrationWatcher,
  aircraftWatcher,
  operatorWatcher,
  areaWatcher,
]

const WATCHERS = watchers.map((watcher) => watcher())

export default function* rootSaga() {
  yield all(WATCHERS)
}
