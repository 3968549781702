import { createAction } from 'redux-actions';
import {
  GET_REGISTRATIONS,
  SET_REGISTRATIONS,
  DELETE_REGISTRATION,
  UPDATE_REGISTRATION,
  ADD_NEW_REGISTRATION
} from '../names';

export const getRegistrations = createAction(GET_REGISTRATIONS);
export const setRegistrations = createAction(SET_REGISTRATIONS);

export const updateRegistration = createAction(UPDATE_REGISTRATION);
export const deleteRegistration = createAction(DELETE_REGISTRATION);
export const addNewRegistration = createAction(ADD_NEW_REGISTRATION);
