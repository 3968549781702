import React, { Suspense, lazy } from "react"
import { Switch, Route } from "react-router-dom"

import Loading from "components/Loading"
import NotFound from "views/NotFound"

import Layout from "./Layout"
import Authenticated from "./Authenticated"

// route listing
const Reports = lazy(() => import("views/Reports"))
const Questionnaires = lazy(() => import("views/Questionnaires"))
const Vehicles = lazy(() => import("views/Vehicles"))
const Airlines = lazy(() => import("views/Airlines"))
const Registrations = lazy(() => import("views/Registrations"))
const AircraftPosition = lazy(() => import("views/AircraftPosition"))
const AircraftPositionII = lazy(() => import("views/AircraftPositionII"))
const Operators = lazy(() => import("views/Operators"))
const Users = lazy(() => import("views/Users"))
const Settings = lazy(() => import("views/Settings"))
const Areas = lazy(() => import("views/Areas"))

const Statistics = lazy(() => import("views/Statistics"))
const SafetyWalkReportStatistics = lazy(
  () => import("views/SafetyWalkReportStatistics")
)
const ITPReportStatistics = lazy(() => import("views/ITPStatistics"))

function Main() {
  return (
    <Authenticated>
      <Layout>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/reports" component={Reports} />
            <Route path="/questionnaires" component={Questionnaires} />
            <Route path="/airlines" component={Airlines} />
            <Route path="/registrations" component={Registrations} />
            <Route path="/areas" component={Areas} />
            <Route path="/aircraft-position" component={AircraftPosition} />
            <Route path="/aircraft-position2" component={AircraftPositionII} />
            <Route path="/operators" component={Operators} />
            <Route path="/users" component={Users} />
            <Route path="/vehicles" component={Vehicles} />
            <Route path="/settings" component={Settings} />
            <Route path="/statistics" component={Statistics} />
            <Route
              path="/safety-walk-report-stats"
              component={SafetyWalkReportStatistics}
            />
            <Route path="/itp-report-stats" component={ITPReportStatistics} />
            <Route path="/" component={Reports} />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Layout>
    </Authenticated>
  )
}

export default Main
