export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const UPDATE_TOKEN = "Update Token"

export const GET_QUESTIONNAIRES = "Get questionnaires"
export const SET_QUESTIONNAIRES = "Set questionnaires"

export const GET_CURRENT_QUESTIONNAIRE = "Get Current questionnaires"
export const SET_CURRENT_QUESTIONNAIRE = "Set Current questionnaires"
export const GET_CURRENT_QUESTIONNAIRE_DROP_DOWN_VALUES =
  "Get Current questionnaires Drop Down Values"
export const SET_CURRENT_QUESTIONNAIRE_DROP_DOWN_VALUES =
  "Set Current questionnaires Drop Down Values"
export const UPDATE_QUESTIONNAIRE = "Update questionnaires"

export const GET_REPORTS = "Get Reports"
export const SET_REPORTS = "Set Reports"
export const GET_REPORTS_USERS = "Get Reports Users"
export const SET_REPORTS_USERS = "Set Reports Users"
export const GET_REPORTS_AREAS = "Get Reports Areas"
export const SET_REPORTS_AREAS = "Set Reports Areas"
export const GET_REPORTS_REGISTRATIONS = "Get Reports Registrations"
export const SET_REPORTS_REGISTRATIONS = "Set Reports Registrations"
export const REPORT_APPROVE = "Report Approve"
export const DELETE_IMAGE = "Delete Image"
export const DELETE_REPORT = "Delete Report"
export const EXPORT_REPORT = "Export Report"
export const EXPORT_REPORT_CSV = "Export Report csv"

export const EDIT_REPORT_ANSWER = "Edit Report Answer"
export const DOWNLOAD_REPORT_PDF = "Download report pdf"
export const SET_REPORT_RESPONSE_ERROR = "Set report response error"

export const UPDATE_REPORT = "Update Report"

export const GET_CURRENT_REPORTS = "Get Current Reports"
export const SET_CURRENT_REPORTS = "Set Current Reports"

export const GET_USER_INFO = "Get User info"
export const SET_USER_INFO = "Set User info"

export const UPDATE_LOCALE = "Update Locale"
export const SET_ERRORS = "Set Errors"
export const CLEAR_ERRORS = "Clear Errors"

export const GET_VEHICLES = "Get Vehicles"
export const SET_VEHICLES = "Set Vehicles"
export const UPDATE_VEHICLE = "Update Vehicle"
export const DELETE_VEHICLE = "Delete Vehicle"
export const ADD_NEW_VEHICLE = "Add New Vehicle"

export const GET_AREAS = "Get Areas"
export const SET_AREAS = "Set Areas"
export const UPDATE_AREA = "Update Area"
export const DELETE_AREA = "Delete Area"
export const ADD_NEW_AREA = "Add New Area"

export const GET_AIRLINES = "Get AIRLINES"
export const SET_AIRLINES = "Set AIRLINES"
export const UPDATE_AIRLINE = "Update AIRLINE"
export const DELETE_AIRLINE = "Delete AIRLINE"
export const ADD_NEW_AIRLINE = "Add New AIRLINE"

export const GET_REGISTRATIONS = "Get REGISTRATIONS"
export const SET_REGISTRATIONS = "Set REGISTRATIONS"
export const UPDATE_REGISTRATION = "Update REGISTRATION"
export const DELETE_REGISTRATION = "Delete REGISTRATION"
export const ADD_NEW_REGISTRATION = "Add New REGISTRATION"

export const GET_AIRCRAFTS = "Get AIRCRAFTS"
export const SET_AIRCRAFTS = "Set AIRCRAFTS"
export const UPDATE_AIRCRAFT = "Update AIRCRAFT"
export const DELETE_AIRCRAFT = "Delete AIRCRAFT"
export const ADD_NEW_AIRCRAFT = "Add New AIRCRAFT"

export const GET_OPERATORS = "Get OPERATORS"
export const SET_OPERATORS = "Set OPERATORS"
export const UPDATE_OPERATOR = "Update OPERATOR"
export const DELETE_OPERATOR = "Delete OPERATOR"
export const ADD_NEW_OPERATOR = "Add New OPERATOR"

export const GET_USERS = "Get USERS"
export const SET_USERS = "Set USERS"
export const SET_CURRENT_USER = "Set Current USERS"
export const GET_CURRENT_USER = "Get Current USERS"
export const CREATE_NEW_USER = "Create new User"
export const UPDATE_USER = "Update User"
export const INVITE_USER = "Invite User"
export const DISABLE_USER = "Disable User"
export const ACTIVATE_USER = "Activate User"
export const ENABLE_USER = "Enable User"

export const CHANGE_PASSWORD = "Change Password"
