import { createAction } from "redux-actions"
import {
  GET_REPORTS,
  SET_REPORTS,
  SET_CURRENT_REPORTS,
  GET_CURRENT_REPORTS,
  GET_REPORTS_USERS,
  SET_REPORTS_USERS,
  EDIT_REPORT_ANSWER,
  GET_REPORTS_AREAS,
  SET_REPORTS_AREAS,
  GET_REPORTS_REGISTRATIONS,
  SET_REPORTS_REGISTRATIONS
} from "../names"

export const getReports = createAction(GET_REPORTS)
export const setReports = createAction(SET_REPORTS)
export const getReportsUsers = createAction(GET_REPORTS_USERS)
export const setReportsUsers = createAction(SET_REPORTS_USERS)

export const getReportsAreas = createAction(GET_REPORTS_AREAS)
export const setReportsAreas = createAction(SET_REPORTS_AREAS)

export const getReportsRegistrations = createAction(GET_REPORTS_REGISTRATIONS)
export const setReportsRegistrations = createAction(SET_REPORTS_REGISTRATIONS)

export const editReportAnswer = createAction(EDIT_REPORT_ANSWER)

export const getCurrentReports = createAction(GET_CURRENT_REPORTS)
export const setCurrentReports = createAction(SET_CURRENT_REPORTS)
