import account, { IAccount } from "./account"
import { combineReducers, AnyAction } from "redux"
import { LOGOUT } from "../names"
import questionnaires, { IQuestionnaires } from "./questionnaires"
import reports, { IReports } from "./reports"
import currentQuestionnaire, {
  ICurrentQuestionnaire,
} from "./currentQuestionnaire"
import currentReport, { ICurrentReport } from "./currentReport"
import errors, { IErrors } from "./errors"
import vehicles, { IVehicles } from "./vehicles"
import airlines, { IAirline } from "./airlines"
import registrations, { IRegistration } from "./registrations"
import aircrafts, { IAircrafts } from "./aircraft"
import operators, { IOperators } from "./operators"
import users, { IUsers } from "./users"
import areas, { IAreas } from "./areas"

export interface IReduxStore {
  account: IAccount
  questionnaires: IQuestionnaires
  reports: IReports
  currentQuestionnaire: ICurrentQuestionnaire
  currentReport: ICurrentReport
  errors: IErrors
  vehicles: IVehicles
  airlines: IAirline
  registrations: IRegistration
  aircrafts: IAircrafts
  operators: IOperators
  users: IUsers
  areas: IAreas
}

const appReducer = combineReducers({
  account,
  questionnaires,
  reports,
  currentQuestionnaire,
  currentReport,
  users,
  errors,
  vehicles,
  airlines,
  registrations,
  aircrafts,
  operators,
  areas,
})

const rootReducer = (state: IReduxStore | undefined, action: AnyAction) => {
  if (action.type === LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
