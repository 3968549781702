import { AnyAction } from "redux"
import { SET_REGISTRATIONS } from "../names"

type Registration = {
  id: string
  name: Record<string, string>
  airport: string
  available: boolean
}

export type IRegistration = {
  data: Registration[]
  limit: number
  offset: number
  total: number
}

export const initRegistrations = {
  data: [],
  limit: 10,
  offset: 0,
  total: 0,
}

function registrations(state: IRegistration = initRegistrations, action: AnyAction) {
  switch (action.type) {
    case SET_REGISTRATIONS:
      return action.payload

    default:
      return state
  }
}

export default registrations
